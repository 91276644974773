import type { ParsedUrlQuery } from 'querystring';

import {
  getProductsWithAllDetails,
  type ProductWithPrices,
} from 'productSelection/services/product';
import type { DigitalProduct, Product } from 'productSelection/types/products';
import type { SkuPriceData } from 'shared/infra/storefront/markets/prices';
import { reorderCatalogByQuery } from 'shared/services/ProductService';

export enum ProductCardSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
  xxl = 'xxl',
  xxxl = 'xxxl',
}

export const getProductsOnQuery = (query: ParsedUrlQuery): string[] => {
  const productsQueryParam = query.products || query.product;
  const productsOnQuery = Array.isArray(productsQueryParam)
    ? productsQueryParam
    : productsQueryParam?.split(',');

  return productsOnQuery;
};

export const getOrderedProducts = (
  products: Product[],
  digitalProducts: DigitalProduct[],
  prices: Record<string, SkuPriceData>,
  locale: string,
  query: ParsedUrlQuery,
  taxMessage = '',
): ProductWithPrices[] | Partial<ProductWithPrices>[] => {
  const allProducts = [...products, ...digitalProducts];
  const productsOnQuery = getProductsOnQuery(query);
  const orderedProductList = reorderCatalogByQuery(
    allProducts,
    productsOnQuery,
  );

  const productList = getProductsWithAllDetails(
    orderedProductList,
    prices,
    locale,
    taxMessage,
  );

  return productList;
};
